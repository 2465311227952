import React, { useEffect, useState, useRef, useContext } from 'react'
import { useSelector } from 'react-redux'
import decodeJwt from 'jwt-decode'
import projectXUI from 'project-x-ui'
import FlipMove from 'react-flip-move'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDebouncedLoader } from 'use-debounced-loader'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import CloseIcon from '@mui/icons-material/Close'

import coinSvg from '../MessageInput/assets/dollar.svg?url'

import { useSrpcApi } from '../../../../../../hooks/useSrpcApi'

const {
  leafs: { QuestionCard },
  shallow: { QuestionCardsRow },
  context: { MainScreenSwipeContext }
} = projectXUI

export const MessageList = ({ jwt, onUserClick }) => {
  const [messages, setMessages] = useState(null)
  const sortAndFilterForMessages = useSelector(state => state.sortAndFilterForMessages)
  const [offset, setOffset] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const ref = useRef()
  const [loading, setLoading] = useState(true)
  const debouncedIsLoading = useDebouncedLoader(loading, 0, 1000)
  const navigate = useNavigate()

  let user

  if (jwt) {
    const jwtDecoded = decodeJwt(jwt)
    user = jwtDecoded
  }

  const srpcApi = useSrpcApi()

  const { setIsModalOpen } = useContext(MainScreenSwipeContext)

  useEffect(() => {
    fetchMessages({ offset: 0, reset: true })
  }, [jwt, sortAndFilterForMessages.filter, sortAndFilterForMessages.sort])

  const getUsersByAnswer = async (questionId, answer) => {
    navigate(`/questions/${questionId}/${answer}/users`)
  }

  const respond = async (messageId, content) => {
    if (!jwt) {
      console.log('cannot respond without being authorized')
      return null
    }
    const { reward } = await srpcApi.saveMessage({ jwt, content, parentMessageId: messageId })
    setMessages(messages.map(m =>
      m._id === messageId
        ? ({ ...m, me: { answer: content, pictureUrl: user?.pictureUrl }, answersCount: { ...m.answersCount, [content]: m.answersCount[content] + 1 } })
        : m
    ))
    toast(() => (
      <div
        style={{ fontFamily: 'IBM Plex Sans', display: 'flex', flexDirection: 'column', gap: 24, width: 320, boxSizing: 'border-box', padding: '0 9px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          {
            reward
              ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 4,
                      width: '100%',
                      alignItems: 'center',
                      position: 'relative',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    🎉 You got reward:
                    <span
                      style={{
                        maxWidth: 75,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '1.2em',
                        fontWeight: 500,
                        marginLeft: 8
                      }}
                      title={reward}
                      alt={reward}
                    >
                      {reward}
                    </span>
                    <div style={{ position: 'relative', height: 24, left: -13 }}>
                      <div
                        className="coin coin--animated"
                        style={{ '--coin-to-x': 'calc(-100px + 24px)', '--coin-to-y': 'calc(-105px + 24px)', '--coin-delay': '0.3s' }}
                      />
                      <div
                        className="coin coin--animated"
                        style={{ '--coin-to-x': 'calc(-70px + 24px)', '--coin-to-y': '-90px', '--coin-delay': '0.1s' }}
                      />
                      <div
                        className="coin coin--animated"
                        style={{ '--coin-to-x': 'calc(-30px + 24px)', '--coin-to-y': '-125px', '--coin-delay': '0s' }}
                      />
                      <div
                        className="coin coin--animated"
                        style={{ '--coin-to-x': 'calc(10px + 24px)', '--coin-to-y': '-130px', '--coin-delay': '0.2s' }}
                      />
                      <div
                        className="coin coin--animated"
                        style={{ '--coin-to-x': 'calc(30px + 24px)', '--coin-to-y': '-100px', '--coin-delay': '0.1s' }}
                      />
                      <div
                        className="coin coin--animated"
                        style={{ '--coin-to-x': 'calc(70px + 24px)', '--coin-to-y': '-95px', '--coin-delay': '0.4s' }}
                      />
                      <div
                        className="coin coin--animated"
                        style={{ '--coin-to-x': 'calc(100px + 24px)', '--coin-to-y': '-100px', '--coin-delay': '0.2s' }}
                      />
                      <img
                        style={{
                          height: 24,
                          width: 54,
                          boxSizing: 'border-box',
                          padding: '0 15px'
                        }}
                        src={coinSvg}
                        alt="coin"
                      />
                    </div>
                  </div>
                )
              : (
                  <p style={{ fontWeight: 'bold' }}>
                    📢{' '}
                    <button className='connect-wallet-toast-button' onClick={() => { toast.dismiss(); setIsModalOpen(true) }}> Connect wallet</button> to get rewards
                  </p>
                )
          }
            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => toast.dismiss()} />
          </div>
        {
          !reward && <p>*reward💰 is in proportion⚖️ to how much followers👨‍👦‍👦 you have!</p>
      }
      </div>
    ), { duration: Infinity })
  }

  const fetchMessages = async ({ offset, reset }) => {
    if (!loading) {
      setLoading(true)
    }
    if (reset) {
      ref.current?.scrollTo(0, 0)
    }
    const { messages: newMessages, hasMore } = await srpcApi.getMessages({
      jwt, offset, sort: sortAndFilterForMessages.sort, filter: sortAndFilterForMessages.filter
    })
    setLoading(false)
    setMessages(reset ? newMessages : [...messages, ...newMessages])

    if (!hasMore) {
      setHasMore(false)
    } else {
      setOffset(offset + 10)
    }
  }

  const onQuestionClick = id => {
    navigate(`/questions/${id}`)
  }

  QuestionCard.prototype = {}

  return (
    <QuestionCardsRow id='questions-desktop-scroll-target' style={{ position: 'relative' }} ref={ref}>
      {messages && messages.length === 0 && (
        <div
          style={{
            fontFamily: 'IBM Plex Sans',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            textAlign: 'center'
          }}
        >
          <span>Empty</span>
        </div>
      )}
      <InfiniteScroll
        scrollableTarget='questions-desktop-scroll-target'
        dataLength={messages?.length || 0}
        next={() => fetchMessages({ offset })}
        hasMore={hasMore}
        style={{ position: 'relative' }}
      >
        <FlipMove
          typeName={null}
          maintainContainerHeight
        >
          {debouncedIsLoading
            ? []
            : messages?.map(m =>
              (
                <QuestionCard
                  key={m._id}
                  name={m.content}
                  answersCount={m.answersCount}
                  me={m.me}
                  shortId={m.shortId}
                  // createdAt={m.createdAt}
                  respond={jwt ? content => respond(m._id, content) : null}
                  onUserClick={() => onUserClick({ _id: m.userId })}
                  createNewGroup={content => getUsersByAnswer(m.shortId, content)}
                  yourOwnQuestion={user?.userId === m.userId}
                  username={m.username}
                  onClick={() => onQuestionClick(m.shortId)}
                />
              )
            )}
        </FlipMove>
      </InfiniteScroll>
    </QuestionCardsRow>
  )
}
