import React, { useContext, useEffect, useState } from 'react'
import projectXUI from 'project-x-ui'
import { useNavigate, useParams } from 'react-router-dom'
import FlipMove from 'react-flip-move'
import { Helmet } from 'react-helmet'

import { useSrpcApi } from '../../../../../../hooks/useSrpcApi'

const {
  leafs: { GroupContentScreen },
  context: { MainScreenSwipeContext }
} = projectXUI

export const UsersByAnswer = ({ jwt }) => {
  const [users, setUsers] = useState({ value: null, total: 0, hasMore: false, offset: 0 })
  const [question, setQuestion] = useState()

  const { screenName } = useContext(MainScreenSwipeContext)

  const srpcApi = useSrpcApi()

  const navigate = useNavigate()

  const { questionId, answer } = useParams()

  const selectUser = async (user) => {
    const userId = typeof user === 'string' ? user : user._id

    navigate(`/users/${userId}`)
  }

  const getUsersByAnswer = async () => {
    const { users: results, total, hasMore } = await srpcApi.getUsersByAnswer({ questionId, answer, offset: users.offset })

    setUsers({
      value: [...users.value, ...results],
      total,
      hasMore,
      offset: users.offset + 20
    })
  }

  const closeUsersByAnswer = () => {
    navigate(jwt ? '/' : '/app')
  }

  useEffect(() => {
    srpcApi.getUsersByAnswer({ questionId, answer, offset: 0 })
      .then(({ users, total, hasMore, question }) => {
        setUsers({
          value: users,
          total,
          hasMore,
          offset: 20
        })
        setQuestion(question)
      })
  }, [])

  const questionTitle = question ? question.slice(0, 20) + (question.length > 20 ? '...' : '') : ''

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>poll.cc | {answer} answers</title>
        <meta name="description" content={`See who answered ${answer} to question "${questionTitle}"`}></meta>
      </Helmet>
      <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}>
        <GroupContentScreen
          style={screenName ? { margin: '10px', maxWidth: 'none' } : { margin: 0, marginTop: 10 }}
          fetchUsers={getUsersByAnswer}
          hasMore={users.hasMore}
          show
          close={closeUsersByAnswer}
          users={users.value || []}
          onUserClick={selectUser}
        />
      </FlipMove>
    </>
  )
}
