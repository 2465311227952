import { SET_SELECTED_GROUP_USERS } from '../actions/selectedGroupUsers'

const initialState = []

export default (state = initialState, action) => {
  const { type, payload: selectedGroupUsers } = action

  const updateStateByFunctionName = {
    [SET_SELECTED_GROUP_USERS]: () => selectedGroupUsers
  }

  const actionHandler = updateStateByFunctionName[type] || (() => state)

  return actionHandler()
}
