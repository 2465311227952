.card {
  border-radius: 4px;
  // box-shadow: 0px 3px 3.84px 2.16px rgba(12, 12, 12, 0.17);
  background: transparent;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px 0 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.username {
  color: #00000063;
  transition: color .2s;
  white-space: pre;
  font-size: 16px;
  // padding-bottom: 16px;
  // height: 40px;
  box-sizing: border-box;
  font-family: 'IBM Plex Sans';
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin-top: 20px;
  align-items: center;
  // & * {
  //   line-height: 1;
  // }
}

.usernameActual {
  cursor: pointer;

  &:hover {
    color: #3b3b3b;
  }  
}

.hint {
  font-size: 16px;
  color: #969696;
  font-weight: 300;
}

.stats {
  width: 100%;

  & > div {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  // position: absolute;
  // bottom: 0
}

.textContainer {
  display: flex;
  justify-content: center;
  width: 160px;
  margin-top: 24px;
}

.text {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.questionText {
  // font-size: 48px;
  font-size: 178px;
  font-weight: 200;
}

.respondButton {
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  border: none;
  background: transparent;

  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  color: #3b3b3b;
  cursor: pointer;

  svg {
    color: #3b3b3b;
  }
}

.arrowDown {
  width: 0; 
  height: 0; 
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  
  border-top: 13px solid #b3b3b3;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: 3px solid #b3b3b3;
  position: relative;
  z-index: 99999;
}

.imgWithArrow {
  position: relative;
  margin-bottom: 4px;
  // bottom: 18px;
}

// .imgContainer {
//   position: relative;
//   width: 100%;
// }

.bars {
  height: 14px;
}

.barsMinWidth {
  & > div {
    min-width: 102px;
  }
}

.close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 24px;

  h2 {
    margin: 0;
    margin-bottom: 20px;
  }

  svg {
    cursor: pointer;
  }
}