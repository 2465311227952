.container {
  margin-top: 10px;
  width: 100%;
  height: 35px;
  background: #2b2b2b0f;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 4px;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  // box-shadow: 0 3px 3.84px 2.16px #0c0c0c2b;
  position: relative;
}

.dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  background: #EAE9E7;
  flex-direction: column;
  gap: 18px;
  padding: 21px 18px;
  box-sizing: border-box;
  color: #2b2b2b;
  font-family: 'IBM Plex Sans';
  bottom: calc(100% + 6px);
  border-radius: 4px;
  border: 1px solid #b3b3b342;
  // box-shadow: 0 3px 3.84px 2.16px #0c0c0c2b;
}

.icon {
  cursor: pointer;
}

.username {
  color: #2b2b2b;
  margin-right: 5px;
}

.twitterSignIn {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #2b2b2b;
  }
}

.divider {
  color: #2b2b2b;
  border-top: 1px solid #25252524;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  padding-top: 12px;
}

@media only screen and (max-width: 945px) {
  .container {
    padding: 25px 20px;
    margin-top: 6px;
  }
}
