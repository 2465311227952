import { SET_USER_QUESTIONS_AND_ANSWERS } from '../actions/setUserQuestionsAndAnswers'

const initialState = { user: null }

export default (state = initialState, action) => {
  const { type, payload } = action

  const updateStateByFunctionName = {
    [SET_USER_QUESTIONS_AND_ANSWERS]: () => payload
  }

  const actionHandler = updateStateByFunctionName[type] || (() => state)

  return actionHandler()
}
