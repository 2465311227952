/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import projectXUI from 'project-x-ui'
import decodeJwt from 'jwt-decode'
import { Helmet } from 'react-helmet'

import { handleTwitterLogin } from '../ActionsPanel/ActionsPanel'

import { useSrpcApi } from '../../../../../../hooks/useSrpcApi'

const {
  leafs: {
    Question
  }
} = projectXUI

export const QuestionDetails = ({ jwt, initialQuestion: r }) => {
  const { id: questionId } = useParams()

  if (!process.env.SERVER) {
    console.log(window.initialQuestion)
  }
  const initialQuestion = process.env.SERVER ? r : window.initialQuestion

  const [question, setQuestion] = useState(initialQuestion)

  const srpcApi = useSrpcApi()

  const navigate = useNavigate()

  useEffect(() => {
    srpcApi.getQuestion({ jwt, questionId }).then(({ question }) => setQuestion(question))
  }, [jwt])

  const getUsersByAnswer = async (questionId, answer) => {
    navigate(`/questions/${questionId}/${answer}/users`)
  }

  const selectUser = async (user) => {
    const userId = typeof user === 'string' ? user : user._id

    navigate(`/users/${userId}`)
  }

  const close = () => {
    navigate(jwt ? '/' : '/app')
  }

  const respond = (messageId, answer) => {
    if (!jwt) {
      console.log('cannot respond without being authorized')
      return null
    }
    srpcApi.saveMessage({ jwt, content: answer, parentMessageId: messageId })

    const newQuestion = {
      ...question,
      me: { answer, pictureUrl: user.pictureUrl },
      answersCount: {
        ...question.answersCount,
        [answer]: question.answersCount[answer] + 1
      }
    }

    setQuestion(newQuestion)
  }

  let user = null
  if (jwt) {
    user = decodeJwt(jwt)
  }

  if (!question) {
    return null
  }

  const title = question ? question.name.slice(0, 50) + (question.name.length > 50 ? '...' : '') : ''
  const description = question
    ? ((question.name.length > 50 ? '...' + question.name.slice(50) : question.name) + ` (${question.answersCount.yes} YES, ${question.answersCount.no} NO)`)
    : ''

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Question
        {...question}
        close={close}
        userPictureUrl={user?.pictureUrl}
        onUserClick={() => selectUser(question.userId)}
        yourOwnQuestion={user?.userId === question.userId}
        respond={jwt ? content => respond(question._id, content) : null}
        createNewGroup={content => getUsersByAnswer(question.shortId, content)}
        handleTwitterLogin={handleTwitterLogin}
      />
    </>
  )
}
