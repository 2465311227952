.button {
  border: none;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 59px;
  height: 33px;
  border-radius: 4px;

  font-size: 18px;
  font-weight: 500;
  color: #222222;

  cursor: pointer;

  transition: box-shadow 0.3s ease-out;

  &:hover {
    box-shadow: none;
  }
}

.yes {
  background: #d24a43;
  box-shadow: 0px 3px 3.84px 2.16px rgba(255, 0, 0, 0.18);
  &:disabled {
    // background: darken(#d24a43, 30%);
    background: desaturate(#d24a43, 30%);
    box-shadow: none;
    cursor: not-allowed;
  }
}

.no {
  background: #3eb5f1;
  box-shadow: 0px 3px 3.84px 2.16px rgba(62, 181, 241, 0.18);
  &:disabled {
    // background: darken(#3eb5f1, 30%);
    background: desaturate(#3eb5f1, 30%);
    box-shadow: none;
    cursor: not-allowed;
  }
}
