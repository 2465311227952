.textarea {
  &::placeholder {
    color: #969696;
    opacity: 1;
    font-size: 18px;
  }
  font-family: inherit;
  box-sizing: border-box;
  border: none;
  outline: none;
  caret-color: #969696;
  color: #bebebe;
  border-radius: 4px;

  resize: none;
  background: #2b2b2b;
  padding: 10px 15px;

  height: 144px;
  width: 100%;
  // box-shadow: 0px 3px 3.84px 2.16px rgba(12, 12, 12, 0.17);
  font-size: 18px;
  font-family: 'IBM Plex Sans';

  &:disabled {
    cursor: not-allowed;
  }
}

.text {
  font-size: 18px;
  font-weight: 300;
  color: #bebebe;
}
