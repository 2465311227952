.bars {
  margin-top: 5px;
  display: flex;
  width: 160px;
  height: 4px;
}

.bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform .3s ease-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.5)
  }
}

.yesBar {
  background: #d24a43;
}

.noBar {
  background: #3eb5f1;
}
