.mainScreen {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 15px 0;
  // border-radius: 4px;
  // background: #f7f7f7 linear-gradient(90deg, rgba(172, 203, 238, 0.33), rgba(231, 240, 253, 0.33))
}

// @media only screen and (max-width: 968px) {
//   .mainScreen {
//     display: none;
//   }
// }

.mainScreenContainer {
  flex-direction: column;
}