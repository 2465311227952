.cardsRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 439px;
}

.scrollContainer {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
}
