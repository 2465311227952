.back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

.screenWithGroupContent {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans';
  max-width: 500px;
  width: calc(100% - 20px);
  gap: 10px;
  overflow: scroll;
  height: calc(100dvh - 20px);
  box-sizing: border-box;
  margin-top: 10px;
}

.usersContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // height: calc(100% - 20px)!important;
}

.screenWithGroupContent > p {
  padding: 30px 15px;
  color: #bebebe;
}

@media only screen and (max-width: 945px) {
  .screenWithGroupContent {
    display: none;
  }
}

.userItem {
  display: flex;
  // align-items: : space-between;
  gap: 12px;
  padding: 15px;
  color: #3b3b3b;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  font-family: 'IBM Plex Sans';
  cursor: pointer;
  flex-direction: column;
  gap: 24px;

  img, .imgFallback {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: 3px solid #d9d7d5;
  }

  svg {
    color: #3b3b3b;
  }

  a {
    border-radius: 4px;
    border: 1px solid #b3b3b3;
    height: 38px;
    width: 38px;
    cursor: pointer;
    padding: 6px;
    transition: box-shadow .2s;
    box-sizing: border-box;

    &:hover {
      box-shadow: 0 3px 9.84px -0.84px #3eb5f12e;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  gap: 8px;
}

.close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;

  h2 {
    margin: 0;
    margin-bottom: 20px;
  }

  svg {
    cursor: pointer;
  }
}