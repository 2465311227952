.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

.textContainer {
  display: flex;
  justify-content: center;
  width: 160px;
}

.text {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.arrowDown {
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #b3b3b3;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
}

.img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 3px solid #b3b3b3;
  position: relative;

  // &:hover::after {
  //   display: block;
  // }
}

.tester {
  content: "";
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 3px solid #b3b3b3;
  background: #b3b3b3;
  display: none;;
  position: absolute;
  z-index: 111;
  bottom: 2px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
}

.imgWithArrow {
  position: absolute;
  bottom: 0;

  &:hover .tester {
    display: flex;
  }
}