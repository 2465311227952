import React, { useContext, useEffect, useState } from 'react'
import projectXUI from 'project-x-ui'
import decodeJwt from 'jwt-decode'
import { useNavigate, useSearchParams } from 'react-router-dom'
import FlipMove from 'react-flip-move'
import { Helmet } from 'react-helmet'

import { useSrpcApi } from '../../../../../../hooks/useSrpcApi'

const {
  leafs: { QuestionsSearch },
  context: { MainScreenSwipeContext }
} = projectXUI

QuestionsSearch.prototype = {}

export const QuestionSearch = ({ jwt }) => {
  const srpcApi = useSrpcApi()

  const { screenName, setShowSearch } = useContext(MainScreenSwipeContext)

  const [questions, setQuestions] = useState({ value: [], total: 0, offset: 0 })

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const { search } = Object.fromEntries(searchParams)

  useEffect(() => {
    searchFn()
  }, [search])

  let user
  if (jwt) {
    user = decodeJwt(jwt)
  }

  const searchFn = async () => {
    const { messages: newQuestions, hasMore, total } = await srpcApi.findMessages({
      jwt,
      search,
      offset: questions.offset
    })

    setQuestions({ value: [...questions.value, ...newQuestions], total, hasMore, offset: questions.offset + 10 })

    setShowSearch(false)
  }

  const backFromSearch = async () => {
    navigate('/')
  }

  const selectUser = async (user) => {
    const userId = typeof user === 'string' ? user : user._id

    navigate(`/users/${userId}`)
  }

  const respondToSearch = (messageId, content) => {
    if (!jwt) {
      console.log('cannot respond without being authorized')
      return null
    }
    srpcApi.saveMessage({ jwt, content, parentMessageId: messageId })
    console.log('questions: ', questions)
    setQuestions({
      ...questions,
      value: questions.value.map(m =>
        m._id === messageId
          ? ({
              ...m,
              me: {
                answer: content,
                pictureUrl: user?.pictureUrl
              },
              answersCount: {
                ...m.answersCount,
                [content]: m.answersCount[content] + 1
              }
            })
          : m
      )
    })
  }

  const getUsersByAnswer = async (questionId, answer) => {
    navigate(`/questions/${questionId}/${answer}/users`)
  }

  const onQuestionClick = id => {
    navigate(`/questions/${id}`)
  }

  if (screenName === 'uninitialized') {
    return null
  }

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>poll.cc | search</title>
        <meta name="description" content="Search questions by specific word or phrase"></meta>
      </Helmet>
      <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}>
        <QuestionsSearch
          style={screenName ? { margin: '10px', maxWidth: 'none' } : {}}
          fetchQuestions={searchFn}
          hasMore={questions.hasMore}
          search={search}
          respond={jwt ? respondToSearch : null}
          createNewGroup={getUsersByAnswer}
          back={backFromSearch}
          questions={questions.value.map(q => ({ ...q, yourOwnQuestion: user ? user.username === q.username : false }))}
          total={questions.total}
          onUserClick={selectUser}
          onQuestionClick={onQuestionClick}
        />
      </FlipMove>
    </>
  )
}
