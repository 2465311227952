.buttons {
  display: flex;
  flex-direction: column;
  // gap: 10px;
  // justify-content: center;

  // & > button {
  //   padding: 10px 0;
  // }
}
