.totalAndBack {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
}

.total {
  color: #3b3b3b;
  font-size: 18px;
  font-weight: 300;
  font-family: 'IBM Plex Sans';
}

.back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

.screenWithGroupContent {
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans';
  max-width: 500px;
  width: calc(100% - 20px);
  gap: 8px;
  overflow: scroll;
  height: calc(100dvh - 20px);
  box-sizing: border-box;
}
