.input {
  margin-right: 5px;

  box-sizing: border-box;
  border: none;
  outline: none;
  caret-color: #969696;
  color: #969696;
  font-weight: bold;
  font-size: 18px;
  border-radius: 4px;

  background: #494949;
  padding: 10px 0 10px 15px;

  height: 36px;
  width: 100%;
}

.text {
  font-size: 18px;
  font-weight: 700;
  color: #bebebe;
}
