.body {
  display: flex;
  justify-content: center;
  width: 100vw;
  // background: #000 linear-gradient(90deg, #accbee63, #49333e);
  background: #ECE9E6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  max-height: 100dvh;
  min-height: 100dvh;
}

.close {
  width: 100%;
  display: flex;
  justify-content: space-between;

  h2 {
    margin: 0;
    margin-bottom: 20px;
  }

  svg {
    cursor: pointer;
  }
}

.address {
  overflow: hidden;
  max-width: 390px;
  display: block;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  gap: 8px;

  b {
    white-space: nowrap;
  }
}

.amount {
  text-overflow: ellipsis;
  max-width: 180px;
  display: block;
  overflow: hidden;
}

.hide {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.connectButton {
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
  background: #2b2b2b0f;
  padding: 9px 16px;
  border-radius: 4px;
  cursor: pointer;
  color: #3b3b3b;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  background: transparent;
  border: 1px solid #59acd6;
  transition: color .1s;
  margin-top: 8px;
}