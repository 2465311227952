.sidebar {
  width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

@media only screen and (max-width: 400px) {
  .sidebar {
    width: 100%;
    // padding: 0 10px;
  }
}

.title {
  margin-bottom: 25px;
}
