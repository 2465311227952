import { SET_QUESTIONS_SEARCH_RESULT } from '../actions/setQuestionSearchResult'
import { UPDATE_QUESTIONS_SEARCH_RESULT } from '../actions/updateQuestionSearchResult'
import { ADD_QUESTIONS_SEARCH_RESULT } from '../actions/addQuestionsSearchResult'

const initialState = { total: 0, offset: 0, hasMore: true, search: null, results: [] }

export default (state = initialState, action) => {
  const { type, payload } = action

  const updateStateByFunctionName = {
    [SET_QUESTIONS_SEARCH_RESULT]: () => payload,
    [UPDATE_QUESTIONS_SEARCH_RESULT]: () => {
      const index = state.results.findIndex(i => i._id === payload._id)
      const newQuestionBase = state.results.find(i => i._id === payload._id)
      const newQuestion = {
        ...newQuestionBase,
        me: { answer: payload.answer, pictureUrl: payload.pictureUrl },
        answersCount: {
          ...newQuestionBase.answersCount,
          [payload.answer]: newQuestionBase.answersCount[payload.answer] + 1
        }
      }

      return { ...state, results: [...state.results.slice(0, index), newQuestion, ...state.results.slice(index + 1)] }
    },
    [ADD_QUESTIONS_SEARCH_RESULT]: () => {
      return { ...state, results: [...(state.results || []), ...payload.results], search: payload.search, total: payload.total, hasMore: payload.hasMore, offset: state.offset + 10 }
    }
  }

  const actionHandler = updateStateByFunctionName[type] || (() => state)

  return actionHandler()
}
