/* eslint-disable */
import React from 'react'

export default () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222 219.8118" width="100%" height="100%">
      <ellipse cx="111" cy="109.9059" rx="110" ry="108.9059" fill="none" stroke="#d24b42" strokeMiterlimit="10" strokeWidth="2"/>
    </svg>
  )
}
