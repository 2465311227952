import { SET_CIRCLE_PARTS } from '../actions/circleParts'

const initialState = []

export default (state = initialState, action) => {
  const { type, payload: circleParts } = action

  const updateStateByFunctionName = {
    [SET_CIRCLE_PARTS]: () => circleParts
  }

  const actionHandler = updateStateByFunctionName[type] || (() => state)

  return actionHandler()
}
