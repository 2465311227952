import React, { useContext, useState, useEffect } from 'react'
import projectXUI from 'project-x-ui'
import { useNavigate, useSearchParams } from 'react-router-dom'
import FlipMove from 'react-flip-move'
import { Helmet } from 'react-helmet'

import { useSrpcApi } from '../../../../../../hooks/useSrpcApi'

const {
  leafs: { UsersSearch },
  context: { MainScreenSwipeContext }
} = projectXUI

UsersSearch.prototype = {}

export const UserSearch = ({ jwt }) => {
  const [users, setUsers] = useState({ value: [], total: 0, offset: 0 })

  const { screenName, setShowSearch } = useContext(MainScreenSwipeContext)

  const srpcApi = useSrpcApi()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const { search } = Object.fromEntries(searchParams)

  const searchFn = async () => {
    const { users: newUsers, hasMore, total } = await srpcApi.searchUsers({
      jwt,
      search,
      offset: users.offset
    })

    setUsers({ value: [...users.value, ...newUsers], total, offset: users.offset + 20, hasMore })

    setShowSearch(false)
  }

  useEffect(() => {
    searchFn()
  }, [search])

  const backFromUserSearch = async () => {
    navigate('/')
  }

  const selectUser = async (user) => {
    const userId = typeof user === 'string' ? user : user._id

    navigate(`/users/${userId}`)
  }

  if (screenName === 'uninitialized') {
    return null
  }

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>poll.cc | user search</title>
        <meta name="description" content="Search users by their twitter/X handle"></meta>
      </Helmet>
      <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}>
        <UsersSearch
          style={screenName ? { margin: '10px', maxWidth: 'none' } : {}}
          fetchUsers={searchFn}
          hasMore={users.hasMore}
          search={search}
          onUserClick={selectUser}
          back={backFromUserSearch}
          users={users.value}
          total={users.total}
        />
      </FlipMove>
    </>
  )
}
