.back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

.totalAndBack {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.total {
  color: #3b3b3b;
  font-size: 18px;
  font-weight: 300;
  font-family: 'IBM Plex Sans';
}

.usersContainer {
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans';
  max-width: 500px;
  width: calc(100% - 20px);
  gap: 8px;
  overflow: scroll;
  height: calc(100dvh - 20px);
  box-sizing: border-box;
  margin-top: 10px;
}

.usersContainer > p {
  padding: 30px 15px;
  color: #3b3b3b;
}

.userItem {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 15px;
  color: #3b3b3b;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  font-family: 'IBM Plex Sans';
  cursor: pointer;

  img {
    border-radius: 50%;
    height: 50px;
    border: 3px solid #d9d7d5;
  }

  svg {
    color: #3b3b3b;
  }

  a {
    border-radius: 4px;
    border: 1px solid #b3b3b3;
    height: 38px;
    width: 38px;
    cursor: pointer;
    padding: 6px;
    transition: box-shadow .2s;
    box-sizing: border-box;

    &:hover {
      box-shadow: 0 3px 9.84px -0.84px #3eb5f12e;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  gap: 8px;
}

.usersItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}