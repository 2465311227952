import { isObject } from 'lodash'
import toast from 'react-hot-toast'

const srpcFunctionNames = [
  'saveMessage',
  'getMessages',
  'createUser',
  'verifyUser',
  'createGroup',
  'getGroups',
  'setSelectedGroup',
  'createCompositeGroup',
  'getUserToken',
  'getCompositeGroupUserCount',
  'resendCode',
  'saveUserCryptoAddress',
  'getUsersByAnswer',
  'searchUsers',
  'findMessages',
  'searchUsers',
  'getUserQuestions',
  'getUserAnswers',
  'getDifference',
  'getUser',
  'getQuestion',
  'hideWalletModal'
]

export const useSrpcApi = () => {
  const callSrpc = (method, params, needToThrow) => {
    return fetch('/', {
      method: 'post',
      body: JSON.stringify({ method, params })
    })
      .then(response => response.json())
      .then(result => {
        if (result.error || !result.success) {
          const message = (isObject(result.error) ? result.error.message : result.error) || 'Internal Server Error'
          if (needToThrow) {
            throw new Error(result.error || 'Internal Server Error')
          } else {
            toast.error(message)
          }
        }

        return result
      })
  }

  return Object.fromEntries(srpcFunctionNames.map(i => [i, (params, needToThrow) => callSrpc(i, params, needToThrow)]))
}
