import { SET_USERS_SEARCH_RESULT } from '../actions/setUsersSearchResult'
import { ADD_USERS_SEARCH_RESULT } from '../actions/addUsersSearchResult'

const initialState = { total: 0, hasMore: true, search: null, results: null, offset: 0 }

export default (state = initialState, action) => {
  const { type, payload } = action

  const updateStateByFunctionName = {
    [SET_USERS_SEARCH_RESULT]: () => payload,
    [ADD_USERS_SEARCH_RESULT]: () => {
      return { ...state, results: [...(state.results || []), ...payload.results], search: payload.search, total: payload.total, hasMore: payload.hasMore, offset: state.offset + 20 }
    }
  }

  const actionHandler = updateStateByFunctionName[type] || (() => state)

  return actionHandler()
}
