.circlesContainer {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
}

.circles {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media only screen and (max-width: 400px) {
  .circlesContainer {
    padding: 0 10px;
    box-sizing: border-box;
  }
}
