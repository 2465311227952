import React, { forwardRef } from 'react'
import projectXUI from 'project-x-ui'
import { useLocalStorage } from '@rehooks/local-storage'
import decodeJwt from 'jwt-decode'
import useCookie from 'react-use-cookie'
import axios from 'axios'

const { leafs: { ActionsPanel: ActionsPanelUI } } = projectXUI

export const handleTwitterLogin = async (state = '') => {
  try {
    const response = await axios.get(`${process.env.HOST}/api/auth/twitter${state}`, {
      withCredentials: true
    })
    window.location.href = response.data.url
  } catch (error) {
    console.error('Error logging in with Twitter:', error)
  }
}

export const ActionsPanel = forwardRef(({ jwt, testUsers, showMyHistory, changeUser }, ref) => {
  const [,, removeJwt] = useLocalStorage('jwt') // eslint-disable-line
  const [,, removeRealJwt] = useLocalStorage('jwt_real') // eslint-disable-line
  const [, setUserToken] = useCookie('jwt', '0')

  let username

  if (jwt) {
    const jwtDecoded = decodeJwt(jwt)
    username = jwtDecoded.username
  }

  const signOut = () => { setUserToken(undefined, { days: -1 }); removeJwt(); removeRealJwt() }

  return (
    <ActionsPanelUI ref={ref} showMyHistory={showMyHistory} testUsers={testUsers} changeUser={changeUser} handleTwitterLogin={() => handleTwitterLogin()} logout={signOut} username={username} />
  )
})
