import { USERS_BY_ANSWER } from '../actions/usersByAnswer'
import { ADD_USERS_BY_ANSWER } from '../actions/addUsersByAnswer'
// import { SET_USER_QUESTIONS_AND_ANSWERS } from '../actions/setUserQuestionsAndAnswers'

const initialState = { show: false, total: 0, hasMore: false, results: [], offset: 0 }

export default (state = initialState, action) => {
  const { type, payload } = action

  const updateStateByFunctionName = {
    [USERS_BY_ANSWER]: () => payload,
    // [SET_USER_QUESTIONS_AND_ANSWERS]: () => initialState,
    [ADD_USERS_BY_ANSWER]: () => {
      return {
        ...state,
        results: [...(state.results || []), ...payload.results],
        total: payload.total,
        hasMore: payload.hasMore,
        offset: state.offset + 20,
        questionId: payload.questionId,
        answer: payload.answer
      }
    }
  }

  const actionHandler = updateStateByFunctionName[type] || (() => state)

  return actionHandler()
}
