.button {
  width: 103px;
  height: 33px;
  font-weight: 500;
  font-size: 18px

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.primary {
  background: #bebebe;

  color: #2b2b2b;
}

.secondary {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #3eb5f175;
  margin-top: 10px;
  color: #bebebe;
  transition: box-shadow .1s;
}

.button:not(:disabled):hover {
  box-shadow: 0 3px 9.84px -0.84px #3eb5f12e;
}

.nobold {
  font-weight: normal!important;
}