.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  padding: 0;

  color: #2b2b2b;
  background: #bebebe;

  cursor: pointer;
  outline: none;
}

.loader {
  span:first-child {
    display: flex;
  }
}
