.container {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 48px;
  // margin: 15px 0;
  box-sizing: border-box;
}

@media only screen and (max-width: 968px) {
  .container {
    justify-content: center;
    width: 100%;
    height: auto;
  }
}
